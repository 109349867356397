import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from 'next/image'

import { Section, Columns, Column, Rows } from '@/components/layout'
import { Text, Card, IconList } from '@/components/common'
import BuyNowCta from '@/components/BuyNowCta'
import crownImg from '@/assets/pricing/crown.jpg'

import styles from './SubscriptionOffers.module.scss'

const getMessageId = id => `pages.pricing.offers.${id}`

const subs = [
  { id: 'vipNeatHomeService', sku: 'neathomevipyr', testId: 'neat-vip' },
]

const OfferCard = ({ hasCrown = true, id, sku }) => {
  const { formatMessage } = useIntl()
  const subName = formatMessage({ id: getMessageId(`${id}.title`) })
  const isVip = id === 'vipService'
  const liCount = 4

  const commonColProps = {
    className: styles.cardColumn,
    mobileBreakpoint: 'small',
  }

  return (
    <Card className={cx(styles.card, isVip && styles.vip)} withShadow>
      <Columns alignCenter gap={0} mobileBreakpoint="small">
        <Column {...commonColProps} gap={8} size={45}>
          {hasCrown && <Image
            alt="crown"
            className={styles.crown}
            src={crownImg}
          />}
          <Text.H3 className={styles.subName}>{subName}</Text.H3>
          <Text.H5 brandPrimary className={styles.subtitle} messageId={getMessageId(`${id}.subtitle`)} />
          <BuyNowCta
            messageProps={{ id: getMessageId('buyNeatHomeCta'), values: { type: subName } }}
            size="large"
            sku={sku}
          />
          <Text element="div" messageId={getMessageId('restrictionNeatHome')} secondary size="xsmall" typeStyle="italic" />
        </Column>
        <Column {...commonColProps} gap={12} size={55}>
          <Text.P messageId={getMessageId(`${id}.description`)} />
          <IconList
            circleIcon
            items={Array.from(Array(liCount).keys())
              .map((ix) => ({ text: formatMessage({ id: getMessageId(`${id}.li${ix + 1}`) }), id: `li${ix}` }))}
            small
          />
        </Column>
      </Columns>
    </Card>
  )
}

OfferCard.propTypes = {
  id: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  hasCrown: PropTypes.bool,
}

export default function SubscriptionOffers() {
  return (
    <Section id="neathome_vip_service_section">
      <Text.H2 center messageId={getMessageId('title')} normal />
      <Rows gap={48}>
        {subs.map(sub => <OfferCard {...sub} key={sub.id} />)}
      </Rows>
    </Section>
  )
}
