import React from 'react'
import PropTypes from 'prop-types'

import { Column, Section, Rows, Row } from '@/components/layout'
import { Text } from '@/components/common'

import styles from './CustomerSupport.module.scss'

const CustomerSupport = ({
  title = 'Neat Comes with Customer Support',
  vipServiceSectionShown = false,
}) => (

  <Section paddingVertical={0}>
    <Row className={styles.customerSupport}>
      <Column center className={styles.customerSupportText} size={40}>
        <Rows className="w-full">
          <h2>{title}</h2>
          <Text.P>You’ll always have unlimited email and chat support to answer any of your questions. Also, our
            user-friendly Help Center Knowledge Base is available to you 24/7.
            {vipServiceSectionShown && <span> If you need more dedicated and priority support then look at our VIP service for <a href="#neathome_vip_service_section">NeatHome below </a></span>}
          </Text.P>
        </Rows>
      </Column>
      <Column className={styles.customerSupportImg} size={40} />
    </Row>
  </Section>
)

CustomerSupport.propTypes = {
  title: PropTypes.string,
  vipServiceSectionShown: PropTypes.bool,
}

export default CustomerSupport
