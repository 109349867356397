import React from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { Link } from '@/components/common'
import TryFreeCta from '@/components/TryFreeCta'
import routes from '@/routes'
import headerLogoImg from '@/assets/header-logo.svg'

import styles from './LogoTryFreeNav.module.scss'

const LogoTryFreeNav = ({ customMessageId = 'cta.tryNeatFREE', trialSku, logoTo = routes.index }) => (
  <div className={styles.header}>
    <div className={styles.content}>
      <Link className={styles.logo} to={logoTo}>
        <Image
          alt="Neat"
          height={24}
          src={headerLogoImg}
          style={{ maxWidth: '140px' }}
          width={92}
        />
      </Link>
      <TryFreeCta
        data-testid="header-try-neat-free"
        messageId={customMessageId}
        round={false}
        size="medium"
        sku={trialSku}
      />
    </div>
  </div>
)

LogoTryFreeNav.propTypes = {
  customMessageId: PropTypes.string,
  trialSku: PropTypes.string,
  logoTo: PropTypes.string,
}

export default LogoTryFreeNav
