import React from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { Column, Section, Rows, Row } from '@/components/layout'
import { Text } from '@/components/common'
import compatibleScannersHeroImg from '@/assets/compatible-scanners/compatible-scanners-hero.png'
import partnersImg from '@/assets/features-potential/partners.jpg'

import styles from './CertifiedHardwarePartners.module.scss'

const CertifiedHardwarePartners = ({ changeLeftOrder = false }) => (

  <Section>
    <Row>
      <Column center className={styles.docScanners} size={40}>
        <Rows className={`w-full ${changeLeftOrder ? styles.reverseOrder : ''}`} textCenter>
          <div>
            <h2 className="c-brand">Compatible Receipt &<br /> Document Scanners.</h2>
            <Text.P>Neat works with any TWAIN compliant scanner.</Text.P>
          </div>
          <Image alt="compatible-scanners" src={compatibleScannersHeroImg} style={{ height: 'auto' }} />
        </Rows>
      </Column>
      <Column center className={styles.hardwarePartners} size={40}>
        <Rows className="w-full">
          <h3>Certified Hardware Partners</h3>
          <Text.P>Neat works with any TWAIN compliant scanner, which includes most scanners on the market today.
            Additionally, Neat works with most major manufacturers to ensure their devices work seamlessly with
            Neat’s software. Connecting these devices is covered as part of our standard support plans.
          </Text.P>
          <Image alt="partners" src={partnersImg} style={{ height: 'auto' }} />
        </Rows>
      </Column>
    </Row>
  </Section>
)

CertifiedHardwarePartners.propTypes = {
  changeLeftOrder: PropTypes.bool,
}

export default CertifiedHardwarePartners
