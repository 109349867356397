import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Column, Section, Rows, Row } from '@/components/layout'
import { Text, ButtonGroup } from '@/components/common'
import BuyNowCta from '@/components/BuyNowCta'
import TryFreeCta from '@/components/TryFreeCta'
import { TRIAL_SKU } from '@/constants'

import styles from './KeepTrackBox.module.scss'

const KeepTrackBox = ({
  bgImage = '/features-potential/organised-bg-half.jpg',
  bgColor = '#EAE9E9',
  extraTitle = '',
  extraText = '',
  buySku = 'neatyr',
  trySku = TRIAL_SKU,
  middleSectionTitleLeft = 'Searchable',
  middleSectionTextLeft = '',
  middleSectionTitleRight = 'Collaborate',
  middleSectionTextRight = '',
  hideMiddleSection = false,
  lightColor = false,
  smallPrint,
  tryCtaMessageId = 'cta.tryNeatFree',
  bottomSectionTitle = 'Easy and efficient – now that’s Neat!',
}) => (

  <Section className={styles.keepTrackSection} gap={0} paddingTop={0}>
    <Row className={styles.keepTrackTop} gap={0}>
      <Column className={styles.organizedImg} size={50} style={{ backgroundImage: `url("${bgImage}")` }}>
        <div className={styles.imgOverlay} style={{ background: `linear-gradient(90deg, rgba(0,0,0,0) 80%, ${bgColor} 100%)` }} />
      </Column>
      <Column className={cx(styles.organizedText, lightColor ? styles.lightColor : '')} size={50} style={{ backgroundColor: bgColor }}>
        <Rows className="w-full">
          <Text.H3>Always Organized</Text.H3>
          <Text.P>Get into the habit of uploading important documents as soon as you get them and you’ll never have
            to worry about paperwork overtaking your desk again.
          </Text.P>
          <Text.P>Creating folders and subfolders is fast and easy, letting you arrange and adapt as you go. Store
            your receipts in subfolders to easily create expense reports, or organize documents in folders
            categorized by project, client, or month. Neat gives you the freedom to organize in a way that makes
            sense for you.
          </Text.P>
          {extraTitle && extraText && (
            <div>
              <Text.H3>{extraTitle}</Text.H3>
              <Text.P>{extraText}</Text.P>
            </div>
          )}
        </Rows>
      </Column>
    </Row>
    {!hideMiddleSection && (
      <Row bgBrand className={styles.keepTrackMiddle}>
        <Column center className={styles.keepTrackMiddleCol} size={50}>
          <Rows className="w-full" textCenter>
            <Text.H3>{middleSectionTitleLeft}</Text.H3>
            {middleSectionTextLeft ? (
              <p>{middleSectionTextLeft}</p>
            ) : (
              <p>
                Reduce manual data entry with Neat’s patented technology that accurately
                extracts key information from your document and turns it into usable data.
              </p>
            )}
          </Rows>
        </Column>
        <Column center className={styles.keepTrackMiddleCol} size={50}>
          <Rows className="w-full" textCenter>
            <Text.H3>{middleSectionTitleRight}</Text.H3>
            {middleSectionTextRight ? (
              <p>{middleSectionTextRight}</p>
            ) : (
              <div>
                <Text.P>Neat makes it easy to work with others, even if they aren’t Neat users. You can even have
                  multiple users for one Neat account, making it perfect for small businesses or families.
                </Text.P>
                <Text.P>Share comments, important files and folders to collaborate with your trusted partners.</Text.P>
              </div>
            )}
          </Rows>
        </Column>
      </Row>
    )}
    <Row className={styles.keepTrackBottom}>
      <Column center>
        <Text.H2>{bottomSectionTitle}</Text.H2>
        <ButtonGroup className={styles.ctaButtons}>
          <BuyNowCta
            messageId="cta.buyNow"
            sku={buySku}
            style={{ border: '0' }}
          />
          <TryFreeCta messageId={tryCtaMessageId} sku={trySku} />
        </ButtonGroup>
        <Text.P>{ smallPrint }</Text.P>
      </Column>
    </Row>
  </Section>
)

KeepTrackBox.propTypes = {
  middleSectionTitleLeft: PropTypes.string,
  middleSectionTextLeft: PropTypes.string,
  middleSectionTitleRight: PropTypes.string,
  middleSectionTextRight: PropTypes.string,
  bgImage: PropTypes.string,
  buySku: PropTypes.string,
  trySku: PropTypes.string,
  bgColor: PropTypes.string,
  extraTitle: PropTypes.string,
  extraText: PropTypes.string,
  hideMiddleSection: PropTypes.bool,
  lightColor: PropTypes.bool,
  smallPrint: PropTypes.string,
  tryCtaMessageId: PropTypes.string,
  bottomSectionTitle: PropTypes.string,
}

export default KeepTrackBox
