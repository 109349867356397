import React from 'react'
import Image from 'next/image'

import TryFreeCta from '@/components/TryFreeCta'
import BuyNowCta from '@/components/BuyNowCta'
import { Text, ButtonGroup } from '@/components/common'
import PricingMain from '@/components/blocks/PricingMain'
import KeepTrackBox from '@/components/sections/KeepTrackBox'
import CertifiedHardwarePartners from '@/components/sections/CertifiedHardwarePartners'
import LogoTryFreeNav from '@/components/sections/LogoTryFreeNav'
import CustomerSupport from '@/components/sections/CustomerSupport'
import { Column, Layout, Row, Rows, Section } from '@/components/layout'
import SubscriptionOffers from '@/components/Pages/NeatHome/SubscriptionOffers'
import trackReceiptsImg from '@/assets/features-potential/track-receipts.jpg'
import id2Img from '@/assets/tax-prep/id2.png'
import transactionStreamImg from '@/assets/transaction-streaming/transaction-stream.png'
import organiseFilesImg from '@/assets/features-potential/organise-files.jpg'
import arrowRightImg from '@/assets/features-potential/arrow-right.png'
import netCashFlow from '@/assets/neathome/net-cash-flow.png'
import connectAccount from '@/assets/automated-insights/connect-account.png'
import realTimeInsights from '@/assets/automated-insights/real-time-insights.png'
import monitorExpense from '@/assets/automated-insights/monitor-expense-neathome.png'
import { NEAT_HOME_TRIAL_SKU } from '@/constants'
import routes from '@/routes'

import styles from './neathome.module.scss'

const pageId = 'neatHome'

export default function NeatHome() {
  return (
    <Layout.Content>
      <LogoTryFreeNav customMessageId="cta.tryNeatHome" logoTo={routes.neatHome} trialSku={NEAT_HOME_TRIAL_SKU} />
      <div className={styles.heroBg}>
        <Section>
          <Column className="w-full">
            <div className={styles.heroHolder}>
              <Text.H1 center size="large">NeatHome</Text.H1>
              <Text.P center className={styles.tagline} onTint size="large">
                Keep your house in order with Neat’s smart organization software. Get a complete picture of your household finances, securely organize and access all your important documents, and quickly run reports for tax, spending, or budgeting. NeatHome is home organization made easy, so you can spend time focused on where you want it.
              </Text.P>
              <ButtonGroup className={styles.ctaButtons} marginTop={32}>
                <BuyNowCta
                  messageId="cta.buyUnderSeven"
                  sku="neathomeyr"
                  style={{ border: '0' }}
                />
                <TryFreeCta messageId="cta.tryNeatHome" sku={NEAT_HOME_TRIAL_SKU} />
              </ButtonGroup>
              <Text.P center className={styles.smallText} onTint>Neat Home is a yearly subscription with a <span>30 Day Money back guarantee</span></Text.P>
            </div>

            <div className={styles.heroImgSection}>
              <div className={styles.imageLabel}>
                <Text.P onTint>Manage your household with ease</Text.P>
                <Image alt="Arrow right" className={styles.hideMobile} src={arrowRightImg} />
              </div>
              <Image alt="Net Cash Flow" src={netCashFlow} style={{ height: 'auto' }} />
            </div>
          </Column>
        </Section>
      </div>

      <div className={styles.unleashPowerSection}>
        <Section>
          <Rows gap={0}>
            <Row center textCenter>
              <h2 className="c-brand">
                Complete the picture of your home finances with Neat
              </h2>
            </Row>
          </Rows>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center size={50}>
              <Image
                alt="Connect bank account"
                className={styles.dataExtractImg}
                src={connectAccount}
                style={{ height: 'auto' }}
              />
            </Column>
            <Column center size={50}>
              <Rows className="w-full">
                <Text.H2>Connect Bank Account</Text.H2>
                <Text.P>Manage all of your financial data in one place by importing all of your bank accounts,
                  credit card accounts, investment accounts, utilities activity, and much more. Pull in any
                  credit lines from home improvement stores for home projects, car payments, etc.
                </Text.P>
              </Rows>
            </Column>
          </Row>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center className={styles.textSection} size={50}>
              <Rows className="w-full">
                <Text.H2>Stream Account Transactions</Text.H2>
                <Text.P>Stream all your activity from any connected account to show trends in
                  spending and income by any timeframe you want. See top expense categories of
                  your spend, and compare actual spend to your household budgets.
                </Text.P>
              </Rows>
            </Column>
            <Column center className={styles.imgSection} size={50}>
              <Image alt="Stream Account Transactions" src={monitorExpense} style={{ height: 'auto' }} />
            </Column>
          </Row>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center size={50}>
              <Image
                alt="Track receipts"
                src={trackReceiptsImg}
              />
            </Column>
            <Column center size={50}>
              <Rows className="w-full">
                <Text.H2>Access Everything All in One Place</Text.H2>
                <Text.P>Capture receipts, bills, statements and other important documents whichever way is easiest
                  for you. Snap a picture with your mobile device, and upload through our mobile app, scan in
                  documents via our desktop app, email them in with your unique Neat email, or import them directly
                  from your computer.
                </Text.P>
                <Text.P>Once in Neat, these documents will be accessible to you anywhere you have access to
                  internet or your cell phone.
                </Text.P>
              </Rows>
            </Column>
          </Row>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center className={styles.textSection} size={50}>
              <Rows className="w-full">
                <Text.H2>Make All Documents Easily Searchable</Text.H2>
                <Text.P>Neat's patented technology accurately extracts text from your documents and turns
                  it into usable, searchable data allowing you to quickly find documents you care about
                  by simply searching any words contained in the text.
                </Text.P>
              </Rows>
            </Column>
            <Column center className={styles.imgSection} size={50}>
              <Image alt="Data Extraction" className={styles.dataExtractImg} src={id2Img} style={{ height: 'auto' }} />
            </Column>
          </Row>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center size={50}>
              <Image alt="Transaction stream" src={transactionStreamImg} style={{ height: 'auto' }} />
            </Column>
            <Column center size={50}>
              <Rows className="w-full">
                <Text.H2>Make All Documents Smarter</Text.H2>
                <p>Use Neat's patented technology to categorize your documents into types (receipts, bills,
                  statements, invoices, recipes, ets.) and extract key fields for each type to organize
                  your documents, and sort/search/report off these key fields.
                </p>
              </Rows>
            </Column>
          </Row>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center className={styles.textSection} size={50}>
              <Rows className="w-full">
                <Text.H2>Organize Files Your Way</Text.H2>
                <Text.P>Organize files by expense type, tax category, document category and more. Quickly
                  find files with our keyword search and filtering capabilities. No matter how you
                  choose to get items into Neat, or what you want to do with them afterward, Neat
                  puts you in control of getting your house in order.
                </Text.P>
              </Rows>
            </Column>
            <Column center className={styles.imgSection} size={50}>
              <Image alt="Organise files" src={organiseFilesImg} style={{ height: 'auto' }} />
            </Column>
          </Row>
        </Section>

        <Section paddingTop={0}>
          <Row>
            <Column center size={50}>
              <Image
                alt="Real-time insights"
                src={realTimeInsights}
              />
            </Column>
            <Column center size={50}>
              <Rows className="w-full">
                <Text.H2>Financial Insights</Text.H2>
                <Text.P>
                  Know what is going on in your home finances at a glance, with real-time insights - including net
                  cash flow, top expense categories, cash balance, profit and loss, and total sales. Generate reports
                  to quickly and easily share with your tax accountant, review your household finances, set goals and
                  budgets for your household.
                </Text.P>
              </Rows>
            </Column>
          </Row>
        </Section>
      </div>

      <Section center className={styles.videoContainer}>
        <div className={styles.videoWrapper}>
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            frameBorder="0"
            height="400"
            referrerPolicy="strict-origin-when-cross-origin"
            src="https://www.youtube-nocookie.com/embed/Z8waZvYbblw?si=OrV-jtAbQEZe2wsU&controls=0&rel=0"
            title="YouTube video player"
            width="700"
          />
        </div>
      </Section>

      <Section center>
        <Text.H2>Easy and efficient – now that’s Neat!</Text.H2>
        <ButtonGroup className={styles.ctaButtons}>
          <BuyNowCta
            data-testid="neathome-buy-now"
            messageId="cta.buyNow"
            sku="neathomeyr"
            style={{ border: '0' }}
          />
          <TryFreeCta data-testid="neathome-try-free" messageId="cta.tryNeatHome" sku="neathomeyrt" />
        </ButtonGroup>
        <Text.P>NeatHome is a yearly subscription with a 30 Day Money back guarantee</Text.P>
      </Section>

      <Section>
        <Rows gap={0}>
          <Row center textCenter>
            <h2 className="c-brand">Keep track with ease</h2>
          </Row>
        </Rows>
      </Section>

      <KeepTrackBox
        bgImage="/features-potential/organized-couple.jpg"
        buySku="neathomeyr"
        middleSectionTextLeft="Manage all of your financial data in one place by importing all of your bank accounts, credit card accounts, and much more.
        Stream all your activity from any connected account to show trends in spending and income by any timeframe you want. See top expense categories of
        your spend, and compare actual spend to your household budgets."
        middleSectionTextRight="Neat makes it easy to access information on the go, with our web and mobile apps, your data is accessible anywhere you
        have a phone or internet. Our bank-level encryption makes certain that you are the only one accessing your information when you need it."
        middleSectionTitleLeft="Manage Home Finances Seamlessly"
        middleSectionTitleRight="Securely Access Anywhere"
        smallPrint="NeatHome is a yearly subscription with a 30 Day Money back guarantee"
        tryCtaMessageId="cta.tryNeatHome"
        trySku="neathomeyrt"
      />

      <CertifiedHardwarePartners changeLeftOrder />

      <CustomerSupport title="NeatHome Comes with Customer Support" vipServiceSectionShown />

      <Section bgBrandTertiary style={{ backgroundColor: '#E9E8FF' }}>
        <PricingMain
          buySku="neathomeyr"
          descriptionLineOne="Our primary platform for home finance management."
          descriptionLineTwo="Get your house in order today."
          hasBottomContent
          learnMoreBt={false}
          onlyNeatHomeLogos
          planTitle="NeatHome"
          price="$79/year"
          showNeatHomeList
          testIdPrefix="pricing-page"
          tryCtaMessageId="cta.tryNeatHome"
          trySku="neathomeyrt"
        />
      </Section>
      <SubscriptionOffers />

    </Layout.Content>
  )
}

NeatHome.layoutProps = {
  showFooter: false,
  pageId,
  showHeader: false,
  shouldRobotsIndex: false,
}
